import { Footer } from "components/Footer/Footer";
import React from "react";
import ContactSection from "../../components/ContactSection/ContactSection";
import Navbar from "../../components/Navbar/Navbar";
import { OurServicesSection } from "../../components/OurServicesSection/OurServicesSection";
import ServicesSection from "../../components/ServicesSection/ServicesSection";
import SkillsSection from "../../components/SkillsSection/SkillsSection";

export const SkillsPage = () => {
  return (
    <div>
      <div>
        <Navbar />
        <div class="padding-top-90">
          <OurServicesSection />
          {/* <ServicesSection /> */}
          {/* <ContactSection /> */}
          <Footer />
        </div>
      </div>
    </div>
  );
};
