import React, { useRef } from "react";
import Fade from "react-reveal/Fade";
import "./ContactSection.css";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import emailjs from "@emailjs/browser";
export default function ContactSection() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_0ed3d6o",
        "template_k03r1en",
        form.current,
        "8L5-nbxLQyHGZfNvQ"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div class="contact-section">
      <div class="main-container">
        <Fade>
          <h3 id="connect-headline">
            Let's <span id="bold-text">Connect</span>
          </h3>

          <p id="connect-desc"></p>
        </Fade>

        <div class="contact-content d-flex align-items-center justify-content-center">
          
          <div class="contact-info-container row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <Fade>
                <p id="contact-info-headline">
                  Contact <span id="bold-text">Info</span>
                </p>
              </Fade>
              <div class="contact-row info-row">
                <Fade>
                  <MailOutlineIcon
                    style={{ color: "rgb(0, 102, 255)" }}
                  ></MailOutlineIcon>
                  <p id="row-info">email.tesseract@gmail.com</p>
                </Fade>
              </div>
              <div class="contact-row info-row">
                <Fade>
                  <PhoneIcon style={{ color: "rgb(0, 102, 255)" }}></PhoneIcon>
                  <p id="row-info">+92 335 1542174</p>
                </Fade>
              </div>
              <div class="contact-row info-row">
                <Fade>
                  <LocationOnIcon
                    style={{ color: "rgb(0, 102, 255)" }}
                  ></LocationOnIcon>
                  <p id="row-info">Rawalpindi, Pakistan</p>
                </Fade>
              </div>

              <div class="socials">
                <a
                  class="btn"
                  href="https://www.facebook.com/Tesseract-Technologies-120257192703621/"
                >
                  <Fade>
                    <FacebookIcon
                      style={{ color: "rgb(0, 102, 255)", fontSize: "40px" }}
                      id="social-icon"
                    ></FacebookIcon>
                  </Fade>
                </a>
                <button class="btn">
                  <Fade>
                    <TwitterIcon
                      style={{ color: "rgb(0, 102, 255)", fontSize: "40px" }}
                      id="social-icon"
                    ></TwitterIcon>
                  </Fade>
                </button>
                <button class="btn">
                  <Fade>
                    <LinkedInIcon
                      style={{ color: "rgb(0, 102, 255)", fontSize: "40px" }}
                      id="social-icon"
                    ></LinkedInIcon>
                  </Fade>
                </button>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <Fade>
                <p id="contact-info-headline">
                  <span id="bold-text">Connect</span> with us
                </p>
              </Fade>
              <form ref={form} onSubmit={sendEmail}>
                <div class="d-flex align-items-center contact-row">
                  {/* <span class="contact-labels col-6">Name</span> */}
                  <input
                    type="text"
                    name="name"
                    class="contact-inputs"
                    placeholder="Name"
                  />
                </div>
                <div class="d-flex align-items-center contact-row">
                  {/* <span class="contact-labels col-6">Email</span> */}
                  <input
                    type="email"
                    name="email"
                    class="contact-inputs "
                    placeholder="Email"
                  />
                </div>
                <div class="d-flex align-items-center contact-row">
                  {/* <span class="contact-labels col-6">Service needed</span> */}
                  <input
                    type="text"
                    name="service"
                    class="contact-inputs "
                    placeholder="Service Required"
                  />
                </div>
                <div class="d-flex align-items-center contact-row">
                  {/* <span class="contact-labels col-6">Description</span> */}
                  <textarea
                    name="description"
                    class="contact-inputs contact-inputs-textarea "
                    placeholder="Your Project Description"
                  />
                </div>
                <div>
                  <input type="submit" value="Send" class="contact-send-btn" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
