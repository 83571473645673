import React from "react";
import "./TeamSection.css";
import Fade from "react-reveal/Fade";
import BassamImg from "../../assets/Team/Bassam.jpg";
import AbdulMoizImg from "../../assets/Team/AbdulMoiz1.jpg";
import AbdullahImg from "../../assets/Team/Abdullah1.jpg";
import ShazaibImg from "../../assets/Team/Shazaib1.jpg";
import BilalImg from "../../assets/Team/Bilal.jpg";
import AliImg from "../../assets/Team/Ali.PNG";
import SharjeelImg from "../../assets/Team/Sharjeel.jpg";
import TalhaImg from "../../assets/Team/Talha1.jpg";
import MohsinImg from "../../assets/Team/Mohsin.jpg";
import UsamaImg from "../../assets/Team/Osama.jpg";
import SheheryarImg from "../../assets/Team/Sheheryar.jfif";

// Mohsin talha bilal zaka
// ali malik abdullah
// shjja sherri

export const TeamSection = () => {
  return (
    <div class="team-section">
      <Fade In>
        <h2 id="service-headline">
          Meet The <span class="primary">Team</span>
        </h2>
        <p id="service-sub-headline">
          Tesseract Technologies specializes in modern application development
          for web and mobile. Providing the best solution for every use case. We
          excell in MERN and MEAN stack development for web applications and
          flutter as well as native application development for mobile
          applications.
        </p>
      </Fade>
      <div class="d-flex justify-content-center ">
        <div class="team-container">
          <div class="row members-row d-flex justify-content-center">
            <div class="team-member-info col-12 col-sm-3">
              <div class="team-member">
                <img src={AbdulMoizImg} class="" />
              </div>
              <div>
                <div class="member-name ">Abdul Moiz</div>
                <div class="member-speciality primary">Software Architect</div>
              </div>
            </div>
            <div class="team-member-info col-12 col-sm-3">
              <div class="team-member">
                <img src={BassamImg} class="" />
              </div>
              <div>
                <div class="member-name ">Muhammad Bassam Ishaq</div>
                <div class="member-speciality primary">Chief Technical Officer</div>
              </div>
            </div>
          </div>
          <div class="row members-row d-flex justify-content-center">
            <div class="team-member-info col-12 col-sm-3">
              <div class="team-member">
                <img src={MohsinImg} class="" />
              </div>
              <div>
                <div class="member-name ">Mohsin Ali Farhat</div>
                <div class="member-speciality primary">
                  {" "}
                  Senior Backend Developer
                </div>
              </div>
            </div>
            <div class="team-member-info col-12 col-sm-3">
              <div class="team-member">
                <img src={BilalImg} class="" />
              </div>
              <div>
                <div class="member-name ">Rameez</div>
                <div class="member-speciality primary">Backend Developer</div>
              </div>
            </div>
            <div class="team-member-info col-12 col-sm-3">
              <div class="team-member">
                <img src={UsamaImg} class="" />
              </div>
              <div>
                <div class="member-name ">Muhammad Usama Umer</div>
                <div class="member-speciality primary">
                  Frontend Developer
                </div>
              </div>
            </div>
            {/* <div class="team-member-info col-12 col-sm-3">
              <div class="team-member">
                <img src={BilalImg} class="" />
              </div>
              <div>
                <div class="member-name ">Bilal Hassan</div>
                <div class="member-speciality primary">
                  Full Stack Developer
                </div>
              </div>
            </div> */}
          </div>
          {/* <div class="row members-row d-flex justify-content-center">
            <div class="team-member-info col-12 col-sm-3">
              <div class="team-member">
                <img src={AbdullahImg} class="" />
              </div>
              <div>
                <div class="member-name ">Abdullah Shahid</div>
                <div class="member-speciality primary">
                  Mobile App Developer
                </div>
              </div>
            </div>
            <div class="team-member-info col-12 col-sm-3">
              <div class="team-member">
                <img src={ShazaibImg} class="" />
              </div>
              <div>
                <div class="member-name ">Malik Shahzaib Haider</div>
                <div class="member-speciality primary">Frontend Developer</div>
              </div>
            </div>
            <div class="team-member-info col-12 col-sm-3">
              <div class="team-member">
                <img src={AliImg} class="" />
              </div>
              <div>
                <div class="member-name ">Muhammad Ali</div>
                <div class="member-speciality primary">Frontend Developer</div>
              </div>
            </div>
          </div> */}
          {/* <div class="row members-row d-flex justify-content-center">
            <div class="team-member-info col-12 col-sm-3">
              <div class="team-member">
                <img src={SheheryarImg} class="" />
              </div>
              <div>
                <div class="member-name ">Sheheryar Ahmad</div>
                <div class="member-speciality primary">
                  Quality Assurance Tester
                </div>
              </div>
            </div>
            <div class="team-member-info col-12 col-sm-3">
              <div class="team-member">
                <img src={SharjeelImg} class="" />
              </div>
              <div>
                <div class="member-name ">Sharjeel Afsar</div>
                <div class="member-speciality primary">
                  Full Stack Developer
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
