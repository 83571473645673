import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Fade from "react-reveal/Fade";
import ProjectsSection from "../../components/ProjectsSection/ProjectsSection";
import './PortfolioPage.css'
import ContactSection from "../../components/ContactSection/ContactSection";
import { Projects } from "../../components/Projects/Projects";
import { Footer } from "components/Footer/Footer";

export const PortfolioPage = () => {
  return (
    <div>
      <Navbar />
      <div class="my-portfolio-section1">
        <div class="col-md-12 see-work">
          <Fade left>
            <h1>See Our Work</h1>
          </Fade>
        </div>
        {/* <div class="col-md-12 new-style">
          <Fade Left>
            <p>
              For Amazing Professional Work{" "}
              <a class="Secondary"> Start With Tesseract </a>
            </p>
          </Fade>
        </div> */}

        <Projects />
      </div>
      {/* <ContactSection /> */}
      <Footer />
    </div>
  );
};
