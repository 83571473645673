import React, { forwardRef, useRef } from "react";
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/';
import HeroSection from "./components/HeroSection/HeroSection";
import StartSection from "./components/StartSection/StartSection";
import PortfolioSection from "./components/PortfolioSection/PortfolioSection";
import AboutSection from "./components/AboutSection/AboutSection";
import QuoteSection from "./components/QuoteSection/QuoteSection";
import ServicesSection from "./components/ServicesSection/ServicesSection";
import CallToSection from "./components/CallToSection/CallToSection";
import SkillsSection from "./components/SkillsSection/SkillsSection";
import ContactSection from "./components/ContactSection/ContactSection";
import Navbar from "./components/Navbar/Navbar";
// import HeroVideo from "./assets/particle-video-light.mp4";
import Typewriter from "typewriter-effect";
import logoImg from "./assets/newLogo1.png";
import tess from "./assets/Tesseract.jpg";
import Fade from "react-reveal/Fade";
import { TeamSection } from "./components/TeamSection/TeamSection";
import ProjectsSection from "./components/ProjectsSection/ProjectsSection";
import { SdlcSection } from "./components/SdlcSection/SdlcSection";
import { Footer } from "./components/Footer/Footer";
import { MarketingSection } from "./components/MarketingSection/MarketingSection";
import NavbarCollapse from "components/NavbarCollapse/NavbarCollapse";

const Home = () => {
  const ref1 = React.createRef();
  const ref2 = React.createRef(null);
  const ref3 = React.createRef(null);
  const ref4 = React.createRef(null);
  const ref5 = React.createRef(null);
  const ref6 = React.createRef(null);
  const ref7 = React.createRef(null);
  const ref8 = React.createRef(null);

  return (
    <div className="App">
      <div class="HeroSection" ref={ref1}>
        {/* <video className="videoTag" autoPlay loop muted>
          <source src={HeroVideo} type="video/mp4" />
        </video> */}
        {/* <img className="videoTag" src={tess}/> */}
        <div>
          <div id="logo">
            {/* <nav class="navbar  shadow">
              <a
                class="navbar-brand btn d-flex align-items-center"
                onClick={() => ref1.current.scrollIntoView()}
              >
                <img src={logoImg} class="logo-tesseract" />
                <div>TESSERACT</div>
              </a>
              <div class="nav-links">
                <div class="nav-single-link">
                  <a
                    class="page-scroll hvr-underline-from-center btn single-link"
                    onClick={() => ref1.current.scrollIntoView()}
                  >
                    Home
                  </a>
                </div>
                <div class="nav-single-link">
                  <a
                    class="page-scroll hvr-underline-from-center btn single-link"
                    onClick={() => ref2.current.scrollIntoView()}
                  >
                    Our Work
                  </a>
                </div>
                <div class="nav-single-link">
                  <a
                    class="page-scroll hvr-underline-from-center btn single-link"
                    onClick={() => ref4.current.scrollIntoView()}
                  >
                    Services
                  </a>
                </div>
                <div class="nav-single-link">
                  <a
                    class="page-scroll hvr-underline-from-center btn single-link"
                    onClick={() => ref6.current.scrollIntoView()}
                  >
                    Our Skills
                  </a>
                </div>
                <div class="nav-single-link">
                  <a
                    class="page-scroll hvr-underline-from-center btn single-link"
                    id="gello"
                    onClick={() => ref8.current.scrollIntoView()}
                  >
                    About Us
                  </a>
                </div>
                <div class="nav-single-link">
                  <a
                    class="page-scroll hvr-underline-from-center btn single-link"
                    id="gello"
                    onClick={() => ref7.current.scrollIntoView()}
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </nav> */}
            <Navbar />
          </div>
        </div>

        {/* <canvas class="particles-js-canvas-el" width="1425" height="400" style="width: 100%; height: 100%;" /> */}
        {/* <h1 class="hero">Tesseract</h1> */}
        <div>
          <div class="TyperDiv ">
            {/* <h1 class="hero">Tesseract </h1> */}
            <div class=" d-flex">
              <div class="typer-1"> We Provide </div>
              <div class="Typer">
                <Typewriter
                  options={{
                    strings: [
                      // "The Premium Software Development Firm. Specializing in Full Stack Development of Web Apps and Flutter Mobile Apps",
                      "Web",
                      "Mobile",
                      "Desktop",
                    ],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 20,
                    delay: 60,
                  }}
                />
              </div>
            </div>
            <div>Solutions</div>
          </div>
          <div class="main-section-desc">
            The Premium Software Development Firm. Specializing in Full Stack
            Development of Web Apps and Flutter Mobile Apps
          </div>
        </div>
      </div>
      <div class="image-section">
        <div class="container-start">
          <Fade left>
            <h3 id="MyHeading1">Need a Job done?</h3>
          </Fade>
          <Fade left>
            <h4 id="MyHeading2">Start with Tesseract Technologies</h4>
          </Fade>
          <Fade left>
            <button
              class="btn btn-default btn-lg hvr-sweep-to-right"
              id="StartBtn"
              onClick={() => ref7.current.scrollIntoView()}
            >
              Get Started
            </button>
          </Fade>
        </div>
      </div>
      {/* <div ref={ref2}>
        <div class="my-portfolio-section">
          <div class="col-md-12 see-work">
            <Fade left>
              <h1>See Our Work</h1>
            </Fade>
          </div>
          <div class="col-md-12 new-style">
            <Fade Left>
              <p>
                For Amazing Professional Work{" "}
                <a
                  onClick={() => ref7.current.scrollIntoView()}
                  class="Secondary"
                >
                  {" "}
                  Start With Tesseract{" "}
                </a>
              </p>
            </Fade>
          </div>

          <ProjectsSection />
        </div>
      </div> */}
      {/* <div ref={ref3}>
        <AboutSection id="section3" />
      </div> */}
      <div ref={ref4}>
        <SdlcSection id="section5" />
      </div>
      <QuoteSection id="section4" />
      <SkillsSection />
      <ServicesSection />
      <MarketingSection />

      <div ref={ref5}>
        <div class="call-to-section">
          <div class="main-gradient">
            <Fade In>
              <h3 id="call-to-headline">
                Like what You see?{" "}
                <span id="contact-tesseract">Talk to Us Now</span> <br />
                Now!
              </h3>
              {/* <p id="call-to-desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec
            odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla
            quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent
            mauris.
          </p> */}
            </Fade>
            <Fade right>
              <button
                id="call-to-button"
                href="contact-section"
                onClick={() => ref7.current.scrollIntoView()}
              >
                Contact Us
              </button>
            </Fade>
          </div>
        </div>
      </div>
      {/* <div ref={ref6}>
        <SkillsSection id="section7" />
      </div> */}

      {/* <div ref={ref8}>
        <TeamSection id="section8" />
      </div> */}

      <div ref={ref7}>
        <ContactSection id="section9" />
      </div>

      <Footer />
      
  
    </div>
  );
};

export default Home;
