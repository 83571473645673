import React from "react";
import "./NavbarCollapse.css";
import hamburger from "../../assets/hamburger.png";
import { useNavigate } from "react-router-dom";

export default function NavbarCollapse() {
  const navigate = useNavigate();
  return (
    <div className="nav-collapse">
      <div class="dropup nav-collapse-btn">
        <button
          class="btn nav-collapse-btn-btn"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img src={hamburger} className="nav-collapse-img" />
        </button>
        <div
          class="dropdown-menu dropdown-menu-right nav-collapse-menu"
          aria-labelledby="dropdownMenuButton"
        >
          <a
            class="dropdown-item nav-collapse-items btn"
            onClick={() => navigate("/")}
          >
            Home
          </a>
          <a
            class="dropdown-item nav-collapse-items btn"
            onClick={() => navigate("/portfolio")}
          >
            Our Work
          </a>
          <a
            class="dropdown-item nav-collapse-items btn"
            onClick={() => navigate("/about")}
          >
            About Us
          </a>
          <a
            class="dropdown-item nav-collapse-items btn"
            onClick={() => navigate("/services")}
          >
            Services
          </a>
          <a
            class="dropdown-item nav-collapse-items btn"
            onClick={() => navigate("/contact")}
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
}
