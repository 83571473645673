import React from "react";
import requirement from "assets/req1.png";

import abc from "assets/req2.png";

// import UXnUI from "../../assets/UXnUI.png";
// import DevelopmentPhase from "assets/development1.svg";
import DevelopmentPhase from "../../assets/DevelopmentPhase.png";

import QAnTesting from "assets/QA.png";
// import QAnTesting from "../../assets/QAnTesting.png";

import ActiveSupport from "assets/req3.png";
// import ActiveSupport from "../../assets/ActiveSupport.png";

import Launching from "assets/launching1.png";
// import Launching from "../../assets/Launching.png";

import Fade from "react-reveal/Fade";

import "./SdlcSection.css";
export const SdlcSection = () => {
  return (
    <div>
      <div class="col-md-12 see-work">
        <Fade left>
          <h1 id="quote-headline">Software Development Process</h1>
        </Fade>
      </div>
      <div class="sdlc-section w-100 ">
        <div class="row">
          <div class="col-6 d-flex align-items-center justify-content-center">
            <img src={requirement} class="sdlc-images" />
          </div>
          <div class="col-6 align-self-center">
            <div class="sdlc-headings">Requirement Analysis</div>
            <div class="text-left">
              We begin by discussing and analyzing our client's needs, sketching
              the outline of the required software, figuring out gaps, and
              proposing solutions alongside the way. Software Requirement
              Specification document is prepared during this process which
              enlists all the requirements needed by the client. This eliminate
              any confusion that might occur in the future.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 align-self-center ">
            <div class="sdlc-headings">UX and UI Design</div>
            <div class="text-left">
              Our in-house creative UI/UX team designs an intuitive interface to
              your software applications retaining the end-customers in mind.
              This step enables the client to able to see the prototype of
              application. This also helps the developers in knowing exactly
              what the design of the application will be.
            </div>
          </div>
          <div class="col-6 d-flex align-items-center justify-content-center">
            <img src={DevelopmentPhase} class="sdlc-images" />
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex align-items-center justify-content-center">
            <img src={abc} class="sdlc-images" />
          </div>
          <div class="col-6 align-self-center">
            <div class="sdlc-headings">Development Phase</div>
            <div class="text-left">
              We begin by discussing and analyzing our client's needs, sketching
              the outline of the required software, figuring out gaps, and
              proposing solutions alongside the way.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 align-self-center">
            <div class="sdlc-headings">Quality Assurance and Testing</div>
            <div class="text-left">
              We begin by discussing and analyzing our client's needs, sketching
              the outline of the required software, figuring out gaps, and
              proposing solutions alongside the way.
            </div>
          </div>
          <div class="col-6 d-flex align-items-center justify-content-center">
            <img src={QAnTesting} class="sdlc-images" />
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex align-items-center justify-content-center">
            <img src={ActiveSupport} class="sdlc-images" />
          </div>
          <div class="col-6 align-self-center">
            <div class="sdlc-headings">Active Support and Assistance</div>
            <div class="text-left">
              We begin by discussing and analyzing our client's needs, sketching
              the outline of the required software, figuring out gaps, and
              proposing solutions alongside the way.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 align-self-center">
            <div class="sdlc-headings">Launching and Marketing</div>
            <div class="text-left">
              We begin by discussing and analyzing our client's needs, sketching
              the outline of the required software, figuring out gaps, and
              proposing solutions alongside the way.
            </div>
          </div>
          <div class="col-6 d-flex align-items-center justify-content-center">
            <img src={Launching} class="sdlc-images" />
          </div>
        </div>
      </div>
    </div>
  );
};
