import React from "react";
import Fade from "react-reveal/Fade";
import "./QuoteSection.css";
export default function QuoteSection() {
  return (
    <div>
      <div class="col-md-12 see-work">
        <Fade left>
          <h1 id='quote-headline'>We are here to make things better</h1>
        </Fade>
      </div>
      <div class=" quote-image-section">
        <h3 class="quote">
          "Patience, persistence and perspiration make an unbeatable
          combination for <span class="primary">success </span>."
        </h3>

        <p class="author">
          NAPOLEON HILL <span class="secondary">- Author</span>
        </p>
      </div>
    </div>
  );
}
