import React from "react";
import "./Navbar.css";
import logoImg from "../../assets/newLogo1.png";

import { useNavigate } from "react-router-dom";
export default function Navbar({}) {
  const navigate = useNavigate();
  return (
    <div>
      <div id="logo">
        <nav class="navbar ">
          <a
            class="navbar-brand btn d-flex align-items-center"
            onClick={() => navigate("/")}
          >
            <img src={logoImg} class="logo-tesseract" />
            <div>TESSERACT</div>
          </a>
          <div class="nav-links">
            <div class="nav-single-link">
              <a
                class="page-scroll hvr-underline-from-center btn single-link"
                onClick={() => navigate("/")}
              >
                Home
              </a>
            </div>
            <div class="nav-single-link">
              <a
                class="page-scroll hvr-underline-from-center btn single-link"
                onClick={() => navigate("/portfolio")}
              >
                Our Work
              </a>
            </div>
            <div class="nav-single-link">
              <a
                class="page-scroll hvr-underline-from-center btn single-link"
                onClick={() => navigate("/about")}
              >
                About Us
              </a>
            </div>
            {/* <div class="nav-single-link">
              <a
                class="page-scroll hvr-underline-from-center btn single-link"
                onClick={() => navigate("/services")}
              >
                Services
              </a>
            </div> */}
            <div class="nav-single-link">
              <a
                class="page-scroll hvr-underline-from-center btn single-link"
                onClick={() => navigate("/services")}
              >
                Services
              </a>
            </div>
            <div class="nav-single-link">
              <a
                class="page-scroll hvr-underline-from-center btn single-link"
                onClick={() => navigate("/contact")}
              >
                Contact Us
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
