import React from "react";
import "./Projects.css";
import studysmartLogo from "../../assets/mybackground3.png";
import shopifyLogo from "../../assets/firstProjectLogo.jpg";
import studysmartMobile from "../../assets/studysmart-mobile-logo1.png";

import naqqaalLogo from "../../assets/Naqqaal-logo.jpg";
import actiiTalkLogo from "../../assets/actiitalklogo1.png";
import whizpoolLogo from "../../assets/whizpool-logo.png";
import symplifiedLogo from "../../assets/logoreq6.png";
import medzserviceLogo from "../../assets/medzservice.png";

import PortfolioThumbnail1 from "../../assets/studysmartWebsite.png";
import PortfolioThumbnail2 from "../../assets/req11.PNG";
import PortfolioThumbnail3 from "../../assets/req12.PNG";
import PortfolioThumbnail4 from "../../assets/mobile-mockup1.PNG";
import PortfolioThumbnail5 from "../../assets/req13.PNG";

export const Projects = () => {
  return (
    <div class="tesseract-projects d-flex align-items-center justify-content-center">
      <div class="tesseract-projects-container">
        <div class="tesseract-projects-container-heading">Our Projects</div>

        <div class="container-fluid portfolio-page-container">
          <a class="btn " href="/projects/shopify-stores">
            <div class="d-flex align-items-center tesseract-single-project-container row   ">
              <div class="tesseract-single-project-image-container-shopify d-flex align-items-center justify-content-center col-sm-12 col-lg-3 ">
                <img
                  src={shopifyLogo}
                  class="tesseract-single-project-image"
                />
              </div>
              <div class="tesseract-single-project-text-container-shopify d-flex justify-content-center align-items-center  col-sm-12 col-lg-9 ">
                <div class="tesseract-single-project-text">
                  <div class="tesseract-single-project-desc-heading">
                    Using Shopify API to automatically upload and update products on shopify
                    stores. Involves taking products returned by the furniture
                    APIs like Ashley Furniture API and converting those
                    accordingly into well organized products and their variants.
                    Eventually using Shopify API for ingesting the products into
                    the shopify stores.
                  </div>
                </div>
              </div>
            </div>
          </a>
          <a class="btn " href="/projects/studysmart">
            <div class="d-flex align-items-center tesseract-single-project-container row   ">
              <div class="tesseract-single-project-image-container d-flex align-items-center justify-content-center col-sm-12 col-lg-3 ">
                <img
                  src={studysmartLogo}
                  class="tesseract-single-project-image"
                />
              </div>
              <div class="tesseract-single-project-text-container d-flex justify-content-center align-items-center  col-sm-12 col-lg-9 ">
                <div class="tesseract-single-project-text">
                  <div class="tesseract-single-project-desc-heading">
                    StudySmart is an online educational platform for the
                    students of Pakistan to learn and teach in a smart way.
                    Students create their own courses, to help their juniors
                    learn, through an easy to use course maker.
                  </div>
                </div>
              </div>
            </div>
          </a>
          <a class="btn " href="/projects/studysmart-mobile">
            <div class="d-flex align-items-center tesseract-single-project-container  row  ">
              <div class="tesseract-studysmart-mobile-image-container d-flex align-items-center justify-content-center col-sm-12 col-lg-3">
                <img src={studysmartLogo} class="studysmart-mobile-logo " />
              </div>
              <div class="tesseract-single-project-text-container-studysmart-mobile d-flex justify-content-center align-items-center col-sm-12 col-lg-9">
                <div class="tesseract-single-project-text">
                  <div class="tesseract-single-project-desc-heading">
                    StudySmart Mobile App
                    <br />
                    An online educational platform for the students of Pakistan
                    to learn and teach in a smart way. Students create their own
                    courses, to help their juniors learn, through an easy to use
                    course maker.
                  </div>
                </div>
              </div>
            </div>
          </a>

          <a class="btn " href="/projects/actiitalk">
            <div class="d-flex align-items-center tesseract-single-project-container   row">
              <div class="tesseract-single-project-image-container-actiitalk d-flex align-items-center justify-content-center col-sm-12 col-lg-3">
                <img
                  src={actiiTalkLogo}
                  class="tesseract-single-project-image"
                />
              </div>
              <div class="tesseract-single-project-text-container-actiitalk d-flex justify-content-center align-items-center col-sm-12 col-lg-9">
                <div class="tesseract-single-project-text">
                  <div class="tesseract-single-project-desc-heading">
                    ActiiTalk is an instant messaging social media app designed
                    for meaningful connections with people to explore shared
                    interests and passions.
                  </div>
                  {/* <div class="tesseract-single-project-description">
                    StudySmart is an online educational platform for the
                    students of Pakistan to learn and teach in a smart way.
                    Students create their own courses, to help their juniors
                    learn, through an easy to use course maker.
                  </div> */}
                </div>
              </div>
            </div>
          </a>

          <a class="btn " href="/projects/medzservice">
            <div class="d-flex align-items-center tesseract-single-project-container   row">
              <div class="tesseract-single-project-image-container-white d-flex align-items-center justify-content-center col-sm-12 col-lg-3">
                <img
                  src={medzserviceLogo}
                  class="tesseract-single-project-image"
                />
              </div>
              <div class="tesseract-single-project-text-container-tesseract d-flex justify-content-center align-items-center col-sm-12 col-lg-9">
                <div class="tesseract-single-project-text">
                  <div class="tesseract-single-project-desc-heading">
                    MedzService is an advanced information technology-based
                    company that provides comprehensive practice management
                    services, revenue cycle management, transcription, and data
                    management solutions. On time accurate claim submissions &
                    effective follow up differentiates us from the rest of the
                    billing companies. We are 100 % HIPAA compliant.
                  </div>
                </div>
              </div>
            </div>
          </a>
          <a class="btn " href="/projects/symplified">
            <div class="d-flex align-items-center tesseract-single-project-container   row">
              <div class="tesseract-single-project-image-container-symplified d-flex align-items-center justify-content-center col-sm-12 col-lg-3">
                <img
                  src={symplifiedLogo}
                  class="tesseract-single-project-image"
                />
              </div>
              <div class="tesseract-single-project-text-container-symplified d-flex justify-content-center align-items-center col-sm-12 col-lg-9">
                <div class="tesseract-single-project-text">
                  <div class="tesseract-single-project-desc-heading">
                    Symplified lets you digitilize your bussiness with your
                    online store. Manage your customer’s journey from order to
                    fulfilment on 1 single platform. Built-in integration with
                    payment and delivery channels. Easy to learn and fast to
                    deploy.
                  </div>
                </div>
              </div>
            </div>
          </a>
          <a class="btn " href="/projects/naqqaal">
            <div class="d-flex align-items-center tesseract-single-project-container   row">
              <div class="tesseract-single-project-image-container d-flex align-items-center justify-content-center col-sm-12 col-lg-3">
                <img src={naqqaalLogo} class="tesseract-single-project-image" />
              </div>
              <div class="tesseract-single-project-text-container-naqqaal d-flex justify-content-center align-items-center col-sm-12 col-lg-9">
                <div class="tesseract-single-project-text">
                  <div class="tesseract-single-project-desc-heading">
                    A Desktop application for transcribing Urdu Speech. Made for
                    Urdu Content Creators to generate automatic Urdu Subtitles
                    and embed in their videos.
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
