import React from "react";
import "./ServicesSection.css";
import Fade from "react-reveal/Fade";
import iPhone from "../../assets/iphone-services.png";
import CreateIcon from "@material-ui/icons/Create";
import WebIcon from "@material-ui/icons/Web";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import ComputerIcon from "@material-ui/icons/Computer";
import MicIcon from "@material-ui/icons/Mic";
import DevicesIcon from "@material-ui/icons/Devices";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";

export default function ServicesSection() {
  return (
    <div class="services-section">
      <div class="services-container">
        <Fade In>
          <h2 id="service-headline">
            What We do, We do <span class="primary">Best</span>
          </h2>
          <p id="service-sub-headline">
            Tesseract Technologies specializes in modern application development
            for web and mobile. Providing the best solution for every use case.
            We excell in MERN and MEAN stack development for web applications
            and flutter as well as native application development for mobile
            applications.
          </p>
        </Fade>

        <div class="service-content d-flex justify-content-center">
          <div class="container-left">
            <div class="left-desc">
              <Fade>
                <div id="one-service">
                  <div>
                    <h4 id="services-heading">Web design</h4>
                    <p id="one-service-desc">
                      We provide you with the design best suited for your
                      requirements
                    </p>
                  </div>
                  <div id="one-icon-left">
                    <WebIcon style={{ color: "rgb(0, 102, 255)", fontSize: "40px" }} />
                  </div>
                </div>
                <div id="one-service">
                  <div>
                    <h4 id="services-heading">Mobile design</h4>
                    <p id="one-service-desc">
                      Our mobile designs are in accordance with modern designing
                      techniques
                    </p>
                  </div>
                  <div id="one-icon-left">
                    <PhoneAndroidIcon
                      style={{ color: "rgb(0, 102, 255)", fontSize: "40px" }}
                    />
                  </div>
                </div>
                <div id="one-service">
                  <div>
                    <h4 id="services-heading">Desktop Apps</h4>
                    <p id="one-service-desc">
                      Get reliable and efficient desktop applications as you
                      require
                    </p>
                  </div>
                  <div id="one-icon-left">
                    <ComputerIcon
                      style={{ color: "rgb(0, 102, 255)", fontSize: "40px" }}
                    />
                  </div>
                </div>
              </Fade>
            </div>
          </div>

          <div class="container-image-service">
            <img src={iPhone} class="" />
          </div>

          <div class="container-right">
            <div class="right-desc">
              <Fade>
                <div id="one-service">
                  <div id="one-icon-right">
                    <DevicesIcon
                      style={{ color: "rgb(0, 102, 255)", fontSize: "40px" }}
                    />
                  </div>
                  <div>
                    <h4 id="services-heading">Multi-platform apps</h4>
                    <p id="one-service-desc">
                      We develop applications for all platforms Web, Mobile and
                      Desktop
                    </p>
                  </div>
                </div>
                <div id="one-service">
                  <div id="one-icon-right">
                    <DeveloperBoardIcon
                      style={{ color: "rgb(0, 102, 255)", fontSize: "40px" }}
                    />
                  </div>
                  <div>
                    <h4 id="services-heading">Backend Development</h4>
                    <p id="one-service-desc">
                      Also Providing efficient and reliable backend support for the applications
                    </p>
                  </div>
                </div>
                <div id="one-service">
                  <div id="one-icon-right">
                    <MicIcon style={{ color: "rgb(0, 102, 255)", fontSize: "40px" }} />
                  </div>
                  <div>
                    <h4 id="services-heading">SEO and Marketing</h4>
                    <p id="one-service-desc">
                      For more insight visit <a href='https://upstrivemarketing.com/'>Upstrive Marketing</a> 
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
