import React from "react";
import "./SkillsSection.css";
import Fade from "react-reveal/Fade";
import redux from "../../assets/Redux_Logo.png";
import nodejs from "../../assets/Nodejs_logo.png";
import expressjs from "../../assets/Express.png";
import mongodb from "../../assets/Mongo.png";
import android from "../../assets/Android.png";
import python from "../../assets/Python.png";
import next from "../../assets/Next.png";
import firebase from "../../assets/firebaselogo1.png";




export default function SkillsSection() {
  return (
    <div class="skills-section">
      <div class="skills-section-inner">
        <div class="main-container">
          <Fade>
            <h4 id="skills-headline">
              Let <span>Our Skills</span> speak for Us
            </h4>
            <p id="skills-subheadline">
              We develop applications in modern technologies that are proven to
              be great for respective application development
            </p>
          </Fade>
        </div>
        <div class="skills-all">
          <div class=" skills-container">
            <div className="row">
              <div class="single-skill">
                <div class="single-skill-inner-container d-flex align-items-center justify-content-center">
                  <img src="/reactlogo1.png" class="single-skill-logo" />
                </div>
              </div>
              <div class="single-skill">
                <div class="single-skill-inner-container d-flex align-items-center justify-content-center ">
                  <img src={next} class="single-skill-logo" />
                </div>
              </div>
              <div class="single-skill">
                <div class="single-skill-inner-container d-flex align-items-center justify-content-center ">
                  <img src={redux} class="single-skill-logo" />
                </div>
              </div>
              <div class="single-skill">
                <div class="single-skill-inner-container d-flex align-items-center justify-content-center ">
                  <img src={nodejs} class="single-skill-logo" />
                </div>
              </div>
              <div class="single-skill">
                <div class="single-skill-inner-container d-flex align-items-center justify-content-center">
                  <img src={expressjs} class="single-skill-logo" />
                </div>
              </div>
              <div class="single-skill">
                <div class="single-skill-inner-container  d-flex align-items-center justify-content-center">
                  <img src={mongodb} class="single-skill-logo" />
                </div>
              </div>
              <div class="single-skill">
                <div class="single-skill-inner-container  d-flex align-items-center justify-content-center">
                  <img src="/ioslogo1.png" class="single-skill-logo" />
                </div>
              </div>
              <div class="single-skill">
                <div class="single-skill-inner-container  d-flex align-items-center justify-content-center">
                  <img src={android} class="single-skill-logo" />
                </div>
              </div>
              <div class="single-skill">
                <div class="single-skill-inner-container  d-flex align-items-center justify-content-center">
                  <img src="/flutterlogo.png" class="single-skill-logo" />
                </div>
              </div>
              <div class="single-skill">
                <div class="single-skill-inner-container  d-flex align-items-center justify-content-center">
                  <img src={firebase} class="single-skill-logo" />
                </div>
              </div>

              <div class="single-skill">
                <div class="single-skill-inner-container  d-flex align-items-center justify-content-center">
                  <img src={python} class="single-skill-logo" />
                </div>
              </div>
              <div class="single-skill">
                <div class="single-skill-inner-container  d-flex align-items-center justify-content-center">
                  <img src="/javalogo.png" class="single-skill-logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
