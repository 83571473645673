import React, { useState, useEffect } from "react";
import ContactSection from "../../components/ContactSection/ContactSection";
import Navbar from "../../components/Navbar/Navbar";
import "./ProjectIntroduction.css";
import { useParams } from "react-router-dom";
import PortfolioThumbnail1 from "assets/mybackground4.png";
import PortfolioThumbnail2 from "assets/req10.png";
import PortfolioThumbnail3 from "assets/req11.PNG";
import PortfolioThumbnail4 from "assets/req12.PNG";
import PortfolioThumbnail5 from "assets/req13.PNG";

// import MobilePic1 from "../../assets/portfolio-thumbnail-01.jpg";
import MobilePic1 from "assets/req14.JPG";

import actiitalk1 from "assets/actiitalk-image1.png";
import actiitalk2 from "assets/actiitalk-image2.png";
import actiitalk3 from "assets/actiitalk-image3.png";
import actiitalk4 from "assets/actiitalk-image4.png";

import medzservice1 from "assets/medzservice1.png";
import medzservice2 from "assets/medzservice2.png";
import medzservice3 from "assets/medzservice5.png";

import symplified1 from "assets/req15.png";
import symplified2 from "assets/req16.png";
import symplified3 from "assets/req17.png";
import symplified4 from "assets/req18.png";

import naqaaal1 from "assets/Naqqaal1.jpg";
import naqaaal2 from "assets/Naqqaal2.jpg";
import naqaaal3 from "assets/Naqqaal3.jpg";
import naqaaal4 from "assets/Naqqaal4.jpg";
import naqaaal5 from "assets/Naqqaal-logo.jpg";

import shopify1 from "assets/ashleySS.jpg";
import shopify2 from "assets/collections.jpg";
import shopify3 from "assets/product.jpg";

import "bootstrap/dist/css/bootstrap.min.css";
import "jquery";
import "bootstrap/dist/js/bootstrap";
import { Footer } from "components/Footer/Footer";

export const ProjectIntroduction = () => {
  const { project } = useParams();

  const [ProjectName, setProjectName] = useState(
    project.charAt().toUpperCase() + project.slice(1)
  );

  const [isViewerOpen, setisViewerOpen] = useState(false);
  const [isSecondViewerOpen, setisSecondViewerOpen] = useState(false);

  const [currentImage, setcurrentImage] = useState(PortfolioThumbnail1);

  const gotoNext = () => {
    setcurrentImage(PortfolioThumbnail2);
  };

  const [projectLink, setprojectLink] = useState([
    "https://www.studysmart.pk",
    "https://www.studysmart.pk",
    "https://www.actiitalk.com/index.html",
    "https://medzservice.com/",
    "https://symplified.biz/index.html",
    "https://tampafurnitureoutlet.us/"
  ]);

  const [headline, setheadline] = useState([
    `An online educational platform for the students of Pakistan to learn and teach in a smart way. Students create their own courses,
to help their juniors learn, through an easy to use course maker.`,
    `Studysmart mobile app takes a new approach to teaching students.An online educational platform for the students of Pakistan to learn and teach in a smart way. Students create their own courses,
    to help their juniors learn, through an easy to use course maker`,
    "ActiiTalk is an instant messaging social media app designed for meaningful connections with people to explore shared interests and passions.",
    `Medical Billing is not only a science, it is also an art. It does not consist of compounding bills, it deals with the very processes of revenue cycle management, which must be understood before they may be guided for increased efficiency.`,
    ` 
  Digitilize your business with your very own online store`,
    `A Desktop application for transcribing Urdu Speech. Made for
  Urdu Content Creators to generate automatic Urdu Subtitles
  and embed in their videos.`,
    `Using Shopify API to automatically upload and update products on shopify
  stores. Involves taking products returned by the furniture
  APIs like Ashley Furniture API and converting those
  accordingly into well organized products and their variants.
  Eventually using Shopify API for ingesting the products into
  the shopify stores.`,
  ]);

  const [headings, setheadings] = useState([
    [
      "Modern Educational Platform",
      "Modern Ways of Teaching",
      "Course Making Made Easy",
      `Study feed to keep you engaged`,
      `Limited Feed`,
    ],
    [
      `Modern Educational Platform`,
      `Modern Ways of Teaching`,
      `Course Making Made Easy`,
      `Study feed to keep you engaged`,
    ],
    [
      `Connecting People With Similar Interests`,
      `Why ActiiTalk Matter`,
      `How is ActiiTalk different?`,
      `How ActiiTalk Works`,
    ],
    [`Med Services`, `The Mission`, `The Vision`, ``],
    [
      `What is Symplified`,
      `How does it work`,
      `Why Symplified`,
      `Symplified Vision`,
    ],
    [`What is Naqqaal`, `The Idea`, `Transcription`, `Text Synchronization`],
    [
      `Furniture Products API `,
      `Organized Products and Collections`,
      `Uploading and Updating Products through Shopify API`,
    ],
  ]);

  const [texts, settexts] = useState([
    [
      `StudySmart is a modern educational platform for the
  students of Pakistan to learn in a smart and efficient
  way. Limited content is shown everyday to the students,
  this helps in retaining the information in a more
  efficient way. It helps the student take small steps and
  turn them into bigger actions. The applications feed like
  structure keeps the students interested and curious
  towards what more they can learn. This concept of giving
  the student a rush like social media posts do helps in
  consuming more information in less time and reduced`,
      `StudySmart lets users create courses or provide coaching
  sessions to students. After creating a course and
  publishing it students can buy the course for the price
  specified by the teacher.
  Another way is providing coaching sessions. Teachers can
  provide online coaching through our video calling
  platform. They can teach either in scheduled class manner
  or provide coaching for a single topic. This method helps
  the students ask questions during the coachings.`,
      `StudySmart features an easy to use course maker. Where the
  teacher can add any type of content they want in the
  course. The system then divides the content into daily
  content. This is based on a maximum time of content
  consumption in each day of a single course. The teacher
  can tweak this to provide more or less content for each
  day.`,
      `Our Model uses an app that functions very similarly to all the current popular social media platforms. This interface will motivate students to open the app as often as they open facebook.`,
      `Unlike Social Media, our application provides a metered dose of information that is specifically related to what they studied in class or in lectures.`,
    ],
    [
      `StudySmart is a modern educational platform for the
  students of Pakistan to learn in a smart and efficient
  way. Limited content is shown everyday to the students,
  this helps in retaining the information in a more
  efficient way. It helps the student take small steps and
  turn them into bigger actions. The applications feed like
  structure keeps the students interested and curious
  towards what more they can learn. This concept of giving
  the student a rush like social media posts do helps in
  consuming more information in less time and reduced`,
      `StudySmart lets users create courses or provide coaching
  sessions to students. After creating a course and
  publishing it students can buy the course for the price
  specified by the teacher.
  Another way is providing coaching sessions. Teachers can
  provide online coaching through our video calling
  platform. They can teach either in scheduled class manner
  or provide coaching for a single topic. This method helps
  the students ask questions during the coachings.`,
      `StudySmart features an easy to use course maker. Where the
  teacher can add any type of content they want in the
  course. The system then divides the content into daily
  content. This is based on a maximum time of content
  consumption in each day of a single course. The teacher
  can tweak this to provide more or less content for each
  day.`,
      `Our Model uses an app that functions very similarly to all the current popular social media platforms. This interface will motivate students to open the app as often as they open facebook. But Unlike Social Media, our application provides a metered dose of information that is specifically related to what they studied in class or in lectures.`,
    ],
    [
      `Have you ever wondered while doing an activity or thinking about something that how many other people are doing the exact same thing at the same time? Have you ever felt that your interests and hobbies do not always match with the people in your social circle? Are you interested in finding out and getting involved in the activities happening around you?

    If the answer to any of the questions is YES, ActiiTalk is the app for you!!!`,
      `Good friends and healthy relationships are very important for a happy, positive and productive life. On ActiiTalk, you will make new connections and form friendships and relationships with people who share the same interests as you.`,
      `We are different from other social media apps.
      To explore the world of ActiiTalk, we will ask you to select your interests and create a profile. Once it’s done, you are ready to find your matches with like-minded people.
      `,
      `When members select their interests and make their profile, they see people, posts, activities and matches based on their interests. It’s all about connecting you to like-minded people.`,
    ],
    [
      `MedzService is an advanced information technology-based company that provides comprehensive practice management services, 
      revenue cycle management, transcription, and data management solutions. On time accurate claim submissions & effective follow up differentiates us from the rest of the billing companies. We are 100 % HIPAA compliant.`,
      `We aim to provide Practice Management Solutions to Maximize Revenues & Productivity. We offer high-quality service to physicians by maximizing their revenue collection and reducing their workload, thereby allowing them to provide the best quality care to their patients. We work with Healthcare Providers to achieve notable results in their earnings, and try to make sure that their billing cycles get smoother more than ever. For this reason, it is very important to ensure that Insurance claims get billed out to insurance companies correctly in the first attempt which in turn provides maximum reimbursement for the billed services.`,
      `Medical Billing is getting more complex nowadays as the healthcare industry is changing with every new day. At the same time, billing demands adhering to new compliance guidelines like HIPAA 5010, ICD 10, EHR ‘meaningful use’ guidelines & etc. Physicians need to keep up with the latest in the field of billing, which often becomes very difficult. The best solution for physicians is to leave medical billing to MedzServices' experts and only focus on their patients' health.`,
      `Symplified lets you digitilize your bussiness with your
      online store. Manage your customer’s journey from order to
      fulfilment on 1 single platform. Built-in integration with
      payment and delivery channels. Easy to learn and fast to
      deploy.`,
    ],
    [
      `Symplified is a unique yet affordable bussiness platform for all business types.
    It is about YOU and the need to get your business online. That is Symplified's dream.`,
      `Our platform aims to give all businesses their very own online store; from home-based to stalls, kiosks, small outlets to even full-fledged outlets or stores.`,
      `Your business needs to get onboard with an online store, if you haven't already, as it is becoming more significant as an add-on to a physical store.`,
      `The ideation and vision is to make owning an online store CHEAPER AND AFFORDABLE for every business owner and build their online business especially with the new norm.

      It's that simple.`,
    ],
    [
      `Naqqaal is a subtitle generating application especially customized for Urdu media content. Users can provide their media content as an input, get the transcript generated automatically, synchronize the text with desired time stamps in the media and get the subtitle file. `,
      `The idea behind this project is to facilitate the Urdu speaking community and content creators to generate subtitles for their media. It can be used by people of various fields such as Journalists, Researchers, Video producers, Podcasters, Lawyers, Coaches, Students, Marketers and Filmmakers etc. for their professional use. It can also be used for transcribing legal hearings, speeches, reports etc.`,
      `The system uses a machine learning model to be able to transcribe an audio. You can use Naqqaal to create a transription of an Urdu audio or video. Naqqaal provides you with a text of what is being said in the media which can be used for captioning on the media.`,
      `After the system gives you the trascription you can synchronize the text with specific times in the recording to create a better experience`,
    ],
    [
      `Firstly the products are obtained through APIs of different furniture products vendors. Some of these vendors include Ashley Furniture, ACME FUrniture and Coaster Furniture.`,
      `The products obtatined from the furniture APIs are then converted into series of products as variants. Also making collections of these series for example bedroom collection, living room collection
      `,
      `Finally the products are uploaded to the corresponding shopify store. These products prices are daily updated and any discontinued products are removed`,
    ],
  ]);

  const [WebPics, setWebPics] = useState([
    [
      PortfolioThumbnail1,
      PortfolioThumbnail2,
      PortfolioThumbnail3,
      PortfolioThumbnail4,
      PortfolioThumbnail5,
    ],
    [naqaaal1, naqaaal2, naqaaal3, naqaaal4, naqaaal5],
    [medzservice1, medzservice2, medzservice3],
    [symplified1, symplified2, symplified3, symplified4],
    [shopify1, shopify2, shopify3],
  ]);

  const [mobileImages, setmobileImages] = useState([
    [MobilePic1, MobilePic1, MobilePic1, MobilePic1],
    [actiitalk1, actiitalk2, actiitalk3, actiitalk4],
  ]);

  const findProjectLink = () => {
    if (project === "studysmart") {
      return projectLink[0];
    } else if (project === "studysmart-mobile") {
      return projectLink[1];
    } else if (project === "actiitalk") {
      return projectLink[2];
    } else if (project === "medzservice") {
      return projectLink[3];
    } else if (project === "symplified") {
      return projectLink[4];
    } else if (project === "shopify-stores") {
      return projectLink[5];
    }
  };

  const findProjectHeadline = () => {
    if (project === "studysmart") {
      return headline[0];
    } else if (project === "studysmart-mobile") {
      return headline[1];
    } else if (project === "actiitalk") {
      return headline[2];
    } else if (project === "medzservice") {
      return headline[3];
    } else if (project === "symplified") {
      return headline[4];
    } else if (project === "naqqaal") {
      return headline[5];
    } else if (project === "shopify-stores") {
      return headline[6];
    }
  };

  const findHeadings = () => {
    if (project === "studysmart") {
      return headings[0];
    } else if (project === "studysmart-mobile") {
      return headings[1];
    } else if (project === "actiitalk") {
      return headings[2];
    } else if (project === "medzservice") {
      return headings[3];
    } else if (project === "symplified") {
      return headings[4];
    } else if (project === "naqqaal") {
      return headings[5];
    } else if (project === "shopify-stores") {
      return headings[6];
    }
  };
  const findTexts = () => {
    if (project === "studysmart") {
      return texts[0];
    } else if (project === "studysmart-mobile") {
      return texts[1];
    } else if (project === "actiitalk") {
      return texts[2];
    } else if (project === "medzservice") {
      return texts[3];
    } else if (project === "symplified") {
      return texts[4];
    } else if (project === "naqqaal") {
      return texts[5];
    } else if (project === "shopify-stores") {
      return texts[6];
    }
  };

  const isProjectMobile = () => {
    if (project === "studysmart-mobile") {
      return false;
    } else if (project === "actiitalk") {
      return false;
    } else {
      return true;
    }
  };

  const mobilePics = () => {
    if (project === "studysmart-mobile") {
      return mobileImages[0];
    } else if (project === "actiitalk") {
      return mobileImages[1];
    } else {
      return mobileImages[0];
    }
  };

  const fintWebPics = () => {
    if (project === "studysmart") {
      return WebPics[0];
    } else if (project === "naqqaal") {
      return WebPics[1];
    } else if (project === "medzservice") {
      return WebPics[2];
    } else if (project === "symplified") {
      return WebPics[3];
    } else if (project === "shopify-stores") {
      return WebPics[4];
    } else {
      return WebPics[0];
    }
  };

  const isContainer5 = () => {
    if (
      project === "naqqaal" ||
      project === "medzservice" ||
      project === "symplified" ||
      project === "shopify-stores"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isContainer4 = () => {
    if (project === "medzservice" ||
    project === "shopify-stores") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <Navbar />
      <div class="project-introduction-container d-flex justify-content-center">
        <div class="project-introduction-box">
          <div class="project-introduction-header d-flex justify-content-center align-items-center">
            <div>{ProjectName}</div>
            {project === "naqqaal" ? (
              ""
            ) : (
              <a href={findProjectLink()} target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50.621"
                  height="50.621"
                  viewBox="0 0 30.621 30.621"
                  class="btn"
                >
                  <g
                    id="Icon_feather-external-link"
                    data-name="Icon feather-external-link"
                    transform="translate(-3 -2.379)"
                  >
                    <path
                      id="Path_575"
                      data-name="Path 575"
                      d="M27,19.5v9a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3V12a3,3,0,0,1,3-3h9"
                      fill="none"
                      stroke="#1cbb6f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                    />
                    <path
                      id="Path_576"
                      data-name="Path 576"
                      d="M22.5,4.5h9v9"
                      fill="none"
                      stroke="#1cbb6f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                    />
                    <path
                      id="Path_577"
                      data-name="Path 577"
                      d="M15,21,31.5,4.5"
                      fill="none"
                      stroke="#1cbb6f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                    />
                  </g>
                </svg>
              </a>
            )}
          </div>
          <div>
            <p class=" project-desc">
              {findProjectHeadline()}
              {project === "naqqaal" ? (
                ""
              ) : (
                <a
                  href={findProjectLink()}
                  target="_blank"
                  class="studysmart-link"
                >
                  Open {ProjectName}
                </a>
              )}
            </p>

            <div class="">
              {isProjectMobile() ? (
                <div>
                  <div class="  project-row-container ">
                    <div class="project-row-single-side d-flex justify-content-center align-items-center text-side ">
                      <div class="project-row-single-side-text-container ">
                        <div class="project-row-single-side-text-heading">
                          {findHeadings()[0]}
                        </div>
                        <div class="project-row-single-side-text    ">
                          {findTexts()[0]}
                        </div>
                      </div>
                    </div>
                    <div class="project-row-single-side  d-flex align-items-center justify-content-center ">
                      <img
                        class="project-intro-images btn"
                        src={fintWebPics()[0]}
                        alt="porftofilio thumbnail"
                        onClick={() => {
                          setisViewerOpen(!isViewerOpen);
                        }}
                      />
                    </div>
                  </div>
                  <div class="  project-row-container">
                    <div class="project-row-single-side   d-flex align-items-center justify-content-center  ">
                      <img
                        class="project-intro-images btn"
                        src={fintWebPics()[1]}
                        alt="porftofilio thumbnail"
                        onClick={() => {
                          setisViewerOpen(!isViewerOpen);
                        }}
                      />
                    </div>
                    <div class="project-row-single-side d-flex justify-content-center align-items-center text-side ">
                      <div class="project-row-single-side-text-container">
                        <div class="project-row-single-side-text-heading">
                          {findHeadings()[1]}
                        </div>
                        <div class="project-row-single-side-text">
                          {findTexts()[1]}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class=" project-row-container">
                    <div class="project-row-single-side d-flex justify-content-center align-items-center text-side ">
                      <div class="project-row-single-side-text-container">
                        <div class="project-row-single-side-text-heading">
                          {findHeadings()[2]}
                        </div>
                        <div class="project-row-single-side-text">
                          {findTexts()[2]}
                        </div>
                      </div>
                    </div>

                    <div class="project-row-single-side   d-flex align-items-center justify-content-center  ">
                      <img
                        class="project-intro-images btn"
                        src={fintWebPics()[2]}
                        alt="porftofilio thumbnail"
                        onClick={() => {
                          setisViewerOpen(!isViewerOpen);
                        }}
                      />
                    </div>
                  </div>
                  {isContainer4() ? (
                    <div class="  project-row-container">
                      <div class="project-row-single-side   d-flex align-items-center justify-content-center  ">
                        <img
                          class="project-intro-images btn"
                          src={fintWebPics()[3]}
                          alt="porftofilio thumbnail"
                          onClick={() => {
                            setisViewerOpen(!isViewerOpen);
                          }}
                        />
                      </div>
                      <div class="project-row-single-side d-flex justify-content-center align-items-center text-side ">
                        <div class="project-row-single-side-text-container">
                          <div class="project-row-single-side-text-heading">
                            {findHeadings()[3]}
                          </div>
                          <div class="project-row-single-side-text">
                            {findTexts()[3]}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {isContainer5() ? (
                    <div class=" project-row-container">
                      <div class="project-row-single-side d-flex justify-content-center align-items-center text-side ">
                        <div class="project-row-single-side-text-container">
                          <div class="project-row-single-side-text-heading">
                            {findHeadings()[4]}
                          </div>
                          <div class="project-row-single-side-text">
                            {findTexts()[4]}
                          </div>
                        </div>
                      </div>

                      <div class="project-row-single-side   d-flex align-items-center justify-content-center  ">
                        <img
                          class="project-intro-images btn"
                          src={fintWebPics()[4]}
                          alt="porftofilio thumbnail"
                          onClick={() => {
                            setisViewerOpen(!isViewerOpen);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {!isProjectMobile() ? (
                <div class="">
                  {/* <div class="project-introduction-secondary-heading d-flex align-items-center justify-content-center">
                  StudySmart Mobile App
                </div> */}
                  <div class="container-fluid project-introduction-secondary-images-container">
                    <div class="row">
                      <div class=" project-introduction-secondary-images-inner-1">
                        <div class="">
                          <div class="project-introduction-secondary-text-container">
                            <div class="project-introduction-secondary-heading">
                              {findHeadings()[0]}
                            </div>
                            <div class=" project-introduction-secondary-text  ">
                              {findTexts()[0]}
                            </div>
                          </div>
                          <img
                            src={mobilePics()[0]}
                            class=" project-introduction-secondary-images  btn"
                            onClick={() => {
                              setisSecondViewerOpen(!isSecondViewerOpen);
                            }}
                          />
                        </div>
                      </div>
                      <div class=" project-introduction-secondary-images-inner-2">
                        <div class="">
                          <img
                            src={mobilePics()[1]}
                            class=" project-introduction-secondary-images btn"
                            onClick={() => {
                              setisSecondViewerOpen(!isSecondViewerOpen);
                            }}
                          />
                          <div class="project-introduction-secondary-text-container">
                            <div class="project-introduction-secondary-heading">
                              {findHeadings()[1]}
                            </div>
                            <div class=" project-introduction-secondary-text">
                              {findTexts()[1]}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class=" project-introduction-secondary-images-inner-3 ">
                        <div class="">
                          <div class="project-introduction-secondary-text-container">
                            <div class="project-introduction-secondary-heading">
                              {findHeadings()[2]}
                            </div>
                            <div class=" project-introduction-secondary-text">
                              {findTexts()[2]}
                            </div>
                          </div>
                          <img
                            src={mobilePics()[2]}
                            class=" project-introduction-secondary-images btn"
                            onClick={() => {
                              setisSecondViewerOpen(!isSecondViewerOpen);
                            }}
                          />
                        </div>
                      </div>
                      <div class=" project-introduction-secondary-images-inner-4">
                        <div class="">
                          <img
                            src={mobilePics()[3]}
                            class=" project-introduction-secondary-images btn"
                            onClick={() => {
                              setisSecondViewerOpen(!isSecondViewerOpen);
                            }}
                          />
                          <div class="project-introduction-secondary-heading">
                            {findHeadings()[3]}
                          </div>
                          <div class=" project-introduction-secondary-text">
                            {findTexts()[3]}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <ContactSection />
      <div class={isViewerOpen ? "images-viewer " : "images-viewer-invisible"}>
        <span
          class="images-viewer-close-icon "
          style={isViewerOpen ? { display: "block" } : { display: "none" }}
          onClick={() => {
            setisViewerOpen(false);
          }}
        >
          x
        </span>
        <div class="images-viewer-inner d-flex align-items-center justify-content-center">
          <div
            id="custCarousel"
            class="carousel slide"
            data-ride="carousel"
            align="center"
            style={isViewerOpen ? { display: "block" } : { display: "none" }}
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src={fintWebPics()[0]}
                  alt="Hills"
                  class="project-intro-carousel-image"
                />
              </div>

              <div class="carousel-item">
                <img
                  src={fintWebPics()[1]}
                  alt="Hills"
                  class="project-intro-carousel-image"
                />
              </div>

              <div class="carousel-item">
                <img
                  src={fintWebPics()[2]}
                  alt="Hills"
                  class="project-intro-carousel-image"
                />
              </div>

              <div class="carousel-item">
                <img
                  src={fintWebPics()[3]}
                  alt="Hills"
                  class="project-intro-carousel-image"
                />
              </div>
            </div>

            <a
              class="carousel-control-prev"
              href="#custCarousel"
              data-slide="prev"
            >
              <span class="carousel-control-prev-icon"></span>
            </a>
            <a
              class="carousel-control-next"
              href="#custCarousel"
              data-slide="next"
            >
              <span class="carousel-control-next-icon"></span>
            </a>

            <ol class="carousel-indicators list-inline">
              <li class="list-inline-item active">
                <a
                  id="carousel-selector-0"
                  class="selected"
                  data-slide-to="0"
                  data-target="#custCarousel"
                >
                  <img src={fintWebPics()[0]} class="img-fluid" />
                </a>
              </li>

              <li class="list-inline-item">
                <a
                  id="carousel-selector-1"
                  data-slide-to="1"
                  data-target="#custCarousel"
                >
                  <img src={fintWebPics()[1]} class="img-fluid" />
                </a>
              </li>

              <li class="list-inline-item">
                <a
                  id="carousel-selector-2"
                  data-slide-to="2"
                  data-target="#custCarousel"
                >
                  <img src={fintWebPics()[2]} class="img-fluid" />
                </a>
              </li>

              <li class="list-inline-item">
                <a
                  id="carousel-selector-2"
                  data-slide-to="3"
                  data-target="#custCarousel"
                >
                  <img src={fintWebPics()[3]} class="img-fluid" />
                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div
        class={
          isSecondViewerOpen ? "images-viewer " : "images-viewer-invisible"
        }
      >
        <span
          class="images-viewer-close-icon "
          style={
            isSecondViewerOpen ? { display: "block" } : { display: "none" }
          }
          onClick={() => {
            setisSecondViewerOpen(false);
          }}
        >
          x
        </span>
        <div class="images-viewer-inner d-flex align-items-center justify-content-center">
          <div
            id="custCarousel1"
            class="carousel slide"
            data-ride="carousel1"
            align="center"
            style={
              isSecondViewerOpen ? { display: "block" } : { display: "none" }
            }
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src={mobilePics()[0]}
                  alt="Hills"
                  class="project-intro-carousel-mobile-image"
                />
              </div>

              <div class="carousel-item">
                <img
                  src={mobilePics()[1]}
                  alt="Hills"
                  class="project-intro-carousel-mobile-image"
                />
              </div>

              <div class="carousel-item">
                <img
                  src={mobilePics()[2]}
                  alt="Hills"
                  class="project-intro-carousel-mobile-image"
                />
              </div>

              <div class="carousel-item">
                <img
                  src={mobilePics()[3]}
                  alt="Hills"
                  class="project-intro-carousel-mobile-image"
                />
              </div>
            </div>

            <a
              class="carousel-control-prev"
              href="#custCarousel1"
              data-slide="prev"
            >
              <span class="carousel-control-prev-icon"></span>
            </a>
            <a
              class="carousel-control-next"
              href="#custCarousel1"
              data-slide="next"
            >
              <span class="carousel-control-next-icon"></span>
            </a>

            <ol class="carousel-indicators list-inline">
              <li class="list-inline-item active">
                <a
                  id="carousel-selector-0"
                  class="selected"
                  data-slide-to="0"
                  data-target="#custCarousel1"
                >
                  <img src={mobilePics()[0]} class="img-fluid" />
                </a>
              </li>

              <li class="list-inline-item">
                <a
                  id="carousel-selector-1"
                  data-slide-to="1"
                  data-target="#custCarousel1"
                >
                  <img src={mobilePics()[1]} class="img-fluid" />
                </a>
              </li>

              <li class="list-inline-item">
                <a
                  id="carousel-selector-2"
                  data-slide-to="2"
                  data-target="#custCarousel1"
                >
                  <img src={mobilePics()[2]} class="img-fluid" />
                </a>
              </li>

              <li class="list-inline-item">
                <a
                  id="carousel-selector-2"
                  data-slide-to="3"
                  data-target="#custCarousel1"
                >
                  <img src={mobilePics()[3]} class="img-fluid" />
                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
