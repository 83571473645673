import React from "react";
import "./MarketingSection.css";
import marketing1 from "../../assets/reqq1.png";
import marketing2 from "../../assets/PPC1.png";
import marketing3 from "../../assets/req4.png";

export const MarketingSection = () => {
  return (
    <div class="marketing-section">
      <div class="marketing-section-inner">
        <h1>
          Professional <span>Marketing</span> of your product
        </h1>
        <div class="marketing-rows">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <div>
                <div class="marketing-headings">Social Media Marketing</div>
                <div class="marketing-text">
                  Social media allows marketers to connect and engage potential
                  customers whether they are at: LinkedIn, Twitter, Youtube,
                  Facebook, Instagram, and even some of the younger platforms
                  like TikTok.
                </div>
              </div>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <img
                class="marketing-images"
                src={marketing1}
                alt="Card image cap"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <img
                class="marketing-images"
                src={marketing2}
                alt="Card image cap"
              />
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <div>
                <div class="marketing-headings">Pay-Per Click</div>
                <div class="marketing-text">
                  Pay-per-click ads provide more value than just getting your
                  brand at the top of the SERP. Google is the most popular
                  search engine this alone benefits your business. Advertising
                  on it get your ad at top.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <div>
                <div class="marketing-headings">Web Analytics</div>
                <div class="marketing-text">
                  Web Analytics is all about data and reporting data but not all
                  data is useful. Different Analytics platforms provides
                  valuable data which can be used to discover hidden trends and
                  insights.
                </div>
              </div>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <img
                class="marketing-images"
                src={marketing3}
                alt="Card image cap"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
