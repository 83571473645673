import React from "react";
import Fade from "react-reveal/Fade";
import "./OurServicesSection.css";
import PortfolioThumbnail1 from "../../assets/studysmartWebsite.png";
import webDesign from "../../assets/req6.png";
import mobileDesign from "../../assets/mobile-designing.svg";
import desktopDesign from "../../assets/desktop-designing.png";

export const OurServicesSection = () => {
  return (
    <div class="services-section">
      <div class="services-container">
        <Fade In left>
          <h2 id="service-headline">
            What We do, We do <span class="primary">Best</span>
          </h2>
        </Fade>
        <div class="container-fluid">
          <div class="our-services-row-container row ">
            <div class="col-lg-6 col-sm-12 align-text-left d-flex align-items-center">
              <div>
                <div class="our-services-service-heading ">Web Designing</div>
                <div class="our-services-service-desc">
                  Web design encompasses many different skills and disciplines
                  in the production and maintenance of websites. The different
                  areas of web design include web graphic design; user interface
                  design (UI design); authoring, including standardised code and
                  proprietary software; user experience design (UX design); and
                  search engine optimization.
                  <br />
                  We provide designs that are tailored to perfectly meet the
                  requirements of our clients.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <img src={webDesign} class="our-services-images" />
            </div>
          </div>

          <div class="our-services-row-container row">
            <div class="col-lg-6 col-sm-12 align-text-left d-flex align-items-center">
              <div>
                <div class="our-services-service-heading">Mobile Designing</div>
                <div class="our-services-service-desc">
                  Mobile app design encompasses both the user interface (UI) and
                  user experience (UX). Designers are responsible for the
                  overall style of the app, including things like the colour
                  scheme, font selection, and the types of buttons and widgets
                  the user will use. So, where do you start? At the beginning.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <img src={mobileDesign} class="our-services-images" />
            </div>
          </div>
          <div class="our-services-row-container row">
            <div class="col-lg-6 col-sm-12 align-text-left d-flex align-items-center">
              <div>
                <div class="our-services-service-heading">Desktop Apps</div>
                <div class="our-services-service-desc">
                  A desktop application is a software program that can be run on
                  a standalone computer to perform a specific task by an
                  end-user. Some desktop applications such as word editor, photo
                  editing app and media player allow you to perform different
                  tasks while other such as gaming apps are developed purely for
                  entertainment.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <img src={desktopDesign} class="our-services-images" />
            </div>
          </div>

          <div class="our-services-row-container row">
            
            <div class="col-lg-6 col-sm-12 align-text-left d-flex align-items-center">
              <div>
                <div class="our-services-service-heading">
                  Backend Development
                </div>
                <div class="our-services-service-desc">
                  Mobile app design encompasses both the user interface (UI) and
                  user experience (UX). Designers are responsible for the
                  overall style of the app, including things like the colour
                  scheme, font selection, and the types of buttons and widgets
                  the user will use. So, where do you start? At the beginning.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <img src={mobileDesign} class="our-services-images" />
            </div>
          </div>
          <div class="our-services-row-container row">
            <div class="col-lg-6 col-sm-12 align-text-left d-flex align-items-center">
              <div>
                <div class="our-services-service-heading">
                  Multiplatform Apps
                </div>
                <div class="our-services-service-desc">
                  A desktop application is a software program that can be run on
                  a standalone computer to perform a specific task by an
                  end-user. Some desktop applications such as word editor, photo
                  editing app and media player allow you to perform different
                  tasks while other such as gaming apps are developed purely for
                  entertainment.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <img src={desktopDesign} class="our-services-images" />
            </div>
          </div>
          <div class="our-services-row-container row">
            
            <div class="col-lg-6 col-sm-12 align-text-left d-flex align-items-center">
              <div>
                <div class="our-services-service-heading">Hosting</div>
                <div class="our-services-service-desc">
                  Mobile app design encompasses both the user interface (UI) and
                  user experience (UX). Designers are responsible for the
                  overall style of the app, including things like the colour
                  scheme, font selection, and the types of buttons and widgets
                  the user will use. So, where do you start? At the beginning.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <img src={mobileDesign} class="our-services-images" />
            </div>
          </div>
          <div class="our-services-row-container row">
            <div class="col-lg-6 col-sm-12 align-text-left d-flex align-items-center">
              <div>
                <div class="our-services-service-heading">Maintenance</div>
                <div class="our-services-service-desc">
                  A desktop application is a software program that can be run on
                  a standalone computer to perform a specific task by an
                  end-user. Some desktop applications such as word editor, photo
                  editing app and media player allow you to perform different
                  tasks while other such as gaming apps are developed purely for
                  entertainment.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <img src={desktopDesign} class="our-services-images" />
            </div>
          </div>
          <div class="our-services-row-container row">
            
            <div class="col-lg-6 col-sm-12 align-text-left d-flex align-items-center">
              <div>
                <div class="our-services-service-heading">Marketing</div>
                <div class="our-services-service-desc">
                  Mobile app design encompasses both the user interface (UI) and
                  user experience (UX). Designers are responsible for the
                  overall style of the app, including things like the colour
                  scheme, font selection, and the types of buttons and widgets
                  the user will use. So, where do you start? At the beginning.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <img src={mobileDesign} class="our-services-images" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
