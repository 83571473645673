import React from "react";
import ContactSection from "../../components/ContactSection/ContactSection";
import Navbar from "../../components/Navbar/Navbar";
export const ContactPage = () => {
  return (
    <div>
      <div>
        <Navbar />
        <div class="padding-top-90">
          <ContactSection />
        </div>
      </div>
    </div>
  );
};
