import React from "react";
import Home from "./Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PortfolioPage } from "./pages/PortfolioPage/PortfolioPage";
import ServicesSection from "./components/ServicesSection/ServicesSection";
import { ServicesPage } from "./pages/ServicesPage/ServicesPage";
import { SkillsPage } from "./pages/SkillsPage/SkillsPage";
import { ContactPage } from "./pages/ContactPage/ContactPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import { ProjectIntroduction } from "./pages/ProjectIntroduction/ProjectIntroduction";
import NavbarCollapse from "components/NavbarCollapse/NavbarCollapse";

const App = () => {
  return (
    <BrowserRouter>
      <div className="App ">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/portfolio" element={<PortfolioPage />} />
          <Route exact path="/about" element={<AboutPage />} />
          {/* <Route exact path="/services" element={<ServicesPage />} /> */}
          <Route exact path="/services" element={<SkillsPage />} />
          <Route exact path="/contact" element={<ContactPage />} />
          <Route exact path="/projects/:project" element={<ProjectIntroduction />} />

        </Routes>
      </div>
      <NavbarCollapse />
    </BrowserRouter>
  );
};
export default App;
