import React from "react";
import "./Footer.css";
export const Footer = () => {
  return (
    <div class=" footer-container">
      <div class=' d-flex justify-content-around  align-items-center footer-main-div'>
        <div class="footer-inner-divs">
          <h3 class="footer-headings">Tesseract Technologies</h3>
          {/* <p class="footer-desc">
          Pakistan's Leading Educational Portal Helping Students teach and
          learn.
        </p> */}
          <div class="d-flex ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.915"
              height="30.854"
              viewBox="0 0 29.915 55.854"
              class="social-icons"
            >
              <path
                id="Icon_awesome-facebook-f"
                data-name="Icon awesome-facebook-f"
                fill="#fff"
                d="M29.564,31.418,31.115,21.31h-9.7V14.75c0-2.765,1.355-5.461,5.7-5.461h4.409V.683A53.772,53.772,0,0,0,23.7,0C15.709,0,10.488,4.841,10.488,13.606v7.7H1.609V31.418h8.879V55.854H21.416V31.418Z"
                transform="translate(-1.609)"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35.561"
              height="28.506"
              viewBox="0 0 37.561 30.506"
              class="social-icons"
            >
              <path
                id="Icon_awesome-twitter"
                data-name="Icon awesome-twitter"
                fill="#fff"
                d="M33.7,10.983c.024.334.024.667.024,1,0,10.177-7.746,21.9-21.9,21.9A21.754,21.754,0,0,1,0,30.431a15.925,15.925,0,0,0,1.859.1,15.417,15.417,0,0,0,9.557-3.289,7.712,7.712,0,0,1-7.2-5.339,9.708,9.708,0,0,0,1.454.119A8.142,8.142,0,0,0,7.7,21.756,7.7,7.7,0,0,1,1.525,14.2v-.1A7.753,7.753,0,0,0,5,15.083a7.71,7.71,0,0,1-2.383-10.3,21.881,21.881,0,0,0,15.873,8.056,8.69,8.69,0,0,1-.191-1.764A7.705,7.705,0,0,1,31.626,5.812a15.156,15.156,0,0,0,4.886-1.859A7.677,7.677,0,0,1,33.128,8.2,15.432,15.432,0,0,0,37.561,7a16.548,16.548,0,0,1-3.861,3.98Z"
                transform="translate(0 -3.381)"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29.427"
              height="29.426"
              class="social-icons"
              viewBox="0 0 59.427 59.426"
            >
              <path
                id="Icon_awesome-linkedin-in"
                data-name="Icon awesome-linkedin-in"
                fill="#fff"
                d="M13.3,59.427H.982V19.751H13.3ZM7.135,14.339a7.169,7.169,0,1,1,7.135-7.2A7.2,7.2,0,0,1,7.135,14.339ZM59.414,59.427H47.12V40.113c0-4.6-.093-10.506-6.406-10.506-6.406,0-7.387,5-7.387,10.174V59.427H21.02V19.751H32.836v5.412h.172c1.645-3.117,5.663-6.407,11.657-6.407,12.469,0,14.761,8.211,14.761,18.876V59.427Z"
                transform="translate(0 -0.001)"
              />
            </svg>
          </div>
        </div>
        <div class="footer-inner-div-links">
          <h3 class="footer-headings">Quick Links</h3>

          <div class="d-flex justify-content-between ">
            <p class="btn">Home</p>
            <p class="btn">About Us</p>
            <p class="btn">Our Work</p>
            <p class="btn">Our Skills</p>
            <p class="btn">Contact Us</p>
          </div>
        </div>
      </div>
    </div>
  );
};
