import { Footer } from 'components/Footer/Footer'
import React from 'react'
import ContactSection from '../../components/ContactSection/ContactSection'
import Navbar from '../../components/Navbar/Navbar'
import { TeamSection } from '../../components/TeamSection/TeamSection'

const AboutPage = () => {
  return (
    <div>
    <div>
      <Navbar />
      <div class='padding-top-90'>
        <TeamSection />
        {/* <ContactSection /> */}
        <Footer />
      </div>
    </div>
  </div>
  )
}

export default AboutPage
